/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { BaseComponentDirective } from '@core/component/base.component';
import { ImpersonateUser } from '@shared/model/impersonate-user.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponentDirective implements OnInit {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    error = '';
    forgotFlow: string;
    password: string;
    email: string;
    loggedInUserId: string;
    loggedInUserName: string;
    constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
        super();
        if (this.authenticationService.userValue) {
            void this.router.navigate(['/']);
        }
    }
    get f() {
        return this.loginForm.controls;
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        this.route.queryParams.subscribe((queryParams: Params) => {
            this.forgotFlow = queryParams.flowType as string;
            setTimeout(() => {
                if (queryParams.flowType) {
                    void this.router.navigate([], {
                        queryParams: {
                            flowType: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                }
            }, 5000);
        });
    }

    onSubmit(): void {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService
            .login(this.email, this.password)
            .pipe(first())
            .subscribe({
                next: () => {
                    let returnUrl = this.route.snapshot.queryParams.returnUrl as string;
                    if (!returnUrl) {
                        returnUrl = '/';
                    }
                    this.loggedInUserId = this.authenticationService.getLoggedInId();
                    this.loggedInUserName = this.authenticationService.getLoggedInUserName();
                    const impersonateUser = new ImpersonateUser({ id: this.loggedInUserId, name: this.loggedInUserName });
                    localStorage.setItem('LastUserImpersonate', JSON.stringify(impersonateUser));
                    void this.router.navigateByUrl(returnUrl);
                },
                error: () => {
                    this.error = 'Unable to log you in. Please check your email and password';
                    this.loading = false;
                },
            });
    }
}
