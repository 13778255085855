import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from '@app/shared/shared.module';
@NgModule({ declarations: [LoginComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA], imports: [CommonModule, ReactiveFormsModule, AuthenticationRoutingModule, ProgressSpinnerModule, SharedModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AuthenticationModule {}
