<div class="d-flex flex-root h-100" id="kt_login_wrapper">
    <!--begin::Login-->
    <div class="login login-1 login-signin-on d-flex flex-lg-row flex-column-fluid bg-white h-100" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside d-flex flex-column flex-row-auto p-7 justify-content-center"
            style="background-color: #177ad8; height: 100vh;">
            <!--begin::Aside Top-->
            <div class="d-flex flex-column-auto flex-column align-content-center justify-content-center">
                <!--begin::Aside header-->
                <a href="#" class="text-center mb-10">
                    <img src="./assets/media/msis/mf.png" class="w-65" alt="" />
                </a>
                <!--end::Aside header-->

                <!--begin::Aside title-->
                <h3 class="font-weight-bolder text-center font-size-h4 font-size-h1-lg" style="color: #fff;">
                    <!-- The only Lead Management & CRM software <br />your agency will ever need<br />
                    with great tools -->
                    The only Lead Management & CRM<br />
                    software your agency will ever need
                </h3>
                <!--end::Aside title-->
            </div>
            <!--end::Aside Top-->

            <!--begin::Aside Bottom-->
            <div class="d-flex justify-content-center mt-4">
                <img src="assets/media/msis/login.png" />
            </div>
            <!--end::Aside Bottom-->
        </div>
        <!-- <p-progressSpinner></p-progressSpinner> -->
        <div
            class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
            <div class="d-flex flex-column-fluid flex-center">
                <div class="login-form login-signin">
                    <!-- <div class="alert alert-info">
                    <strong>Administrator</strong> - U: testuser2 P: TestUser2
                </div> -->
                    <!-- <app-spinner></app-spinner> -->
                    <div class="pb-13 pt-lg-0 pt-5" style="min-width: 400px;">
                        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"></h3>
                    </div>
                    <div *ngIf="forgotFlow" class="alert alert-success mt-3 mb-3">Your password has been changed
                        successfully</div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="email" class="font-size-h6 font-weight-bolder text-dark">Email</label>
                            <input type="text" formControlName="email" [(ngModel)]="email"
                                class="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="password" class="font-size-h6 font-weight-bolder text-dark">Password</label>
                            <a routerLink="/user-settings/forgot-password" class="float-right font-weight-bolder">Forgot
                                Password?</a>
                            <input type="password" formControlName="password" [(ngModel)]="password"
                                class="form-control form-control-solid h-auto py-4 px-6 rounded-lg"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{ error }}</div>
                        <button [disabled]="loading"
                            class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 w-100">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>